<template>
  <el-scrollbar height="100vh">
    <div class="content-main">
      <div class="form-main">
        <div class="head-title">
          <h3 class="title">风格设置</h3>
        </div>
        <!-- <div class="sub-title sub-title_style">
          <span>自定义关键词</span>
        </div> -->
        <div class="form-content">
          <el-form label-position="top">
            <el-form-item clearable label="品类">
              <el-radio-group v-model="formData.product" @change="getTypeName()">
                <el-radio-button v-for="item in typeGroup" :label="item.value" :key="item.value">{{
                    item.label
                  }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item clearable label="自定义关键词">
              <el-input
                  type="textarea"
                  clearable
                  :rows="5"
                  show-word-limit
                  maxlength="2000"
                  placeholder="示范：手持吸尘器、翡翠吊坠"
                  v-model="formData.description"/>
            </el-form-item>
            <el-form-item v-if="formData.product == 'jewelry'" label="类别">
              <el-select class="wid100" style="width:200px" v-model="formData.category" filterable clearable
                         multiple placeholder="请选择">
                <el-option
                    v-for="item in categoryListOther"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formData.product != 'jewelry'" label="类别">
              <el-select class="wid100" style="width:200px" v-model="formData.category" filterable clearable
                        placeholder="请选择">
                <el-option
                    v-for="item in categoryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-form-item>

            <template v-if="formData.product == 'jewelry'">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="适用场景">
                    <el-select v-model="formData.scene" clearable filterable placeholder="请选择">
                      <el-option
                          v-for="item in useScenelist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="工艺">
                    <el-select v-model="formData.workmanship" clearable filterable placeholder="请选择">
                      <el-option
                          v-for="item in technologyList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="图案造型">
                    <el-select v-model="formData.pattern" clearable filterable placeholder="请选择">
                      <el-option
                          v-for="item in imageTypeList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="款式">
                    <el-select v-model="formData.modeling" clearable filterable placeholder="请选择">
                      <el-option
                          v-for="item in styleList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="主材质">
                    <el-select v-model="formData.mainMaterial" clearable filterable placeholder="请选择">
                      <el-option
                          v-for="item in mainMaterialList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="镶嵌材质">
                    <el-select v-model="formData.studdedMaterial" clearable filterable placeholder="请选择">
                      <el-option
                          v-for="item in mosaicMaterial"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="风格">
                    <el-select v-model="formData.region" filterable clearable placeholder="请选择">
                      <el-option
                          v-for="item in styleColorList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </el-form>
        </div>
        <div class="head-title">
          <h3 class="title">图片设置</h3>
        </div>
        <div class="image-setting">
          <div class="sub-title sub-title_style">
            <span>图片尺寸</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="6" v-for="item in imageScaleList" :key="item.size">
              <div class="scale-item center-center" :class="{active: formData.sizeStyle === item.value}"
                   @click="onGetScalc(item)">
                <div>
                  <div class="scale">{{ item.size }}</div>
                  <!-- <div class="scale scale-name">{{ item.label }}</div> -->
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- <div class="sub-title sub-title_style">
            <span>图片尺寸</span>
          </div> -->
          <!-- <el-row :gutter="20">
            <el-col :span="6" v-for="item in sizeGroup" :key="item.label">
              <div class="scale-item center-center" :class="{'active' : formData.size === item.value}" @click="onGetSize(item)">
                <div>
                  <div class="scale">{{ item.label }}</div>
                  <div class="scale-text">{{ item.name }}</div>
                </div>
              </div>
            </el-col>
          </el-row> -->
          <!-- <div class="sub-title sub-title_style">
            <span>图片分辩率</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="6" v-for="item in rainGroup" :key="item.label">
              <div class="scale-item center-center" :class="{'active' : formData.size === item.value}" @click="onSetRatin(item)">
                <div>
                  <div class="scale">{{ item.label }}</div>
                </div>
              </div>
            </el-col>
          </el-row> -->
          <div class="silder-item">
            <div class="sub-title sub-title_style">
              <span>创意度</span>
            </div>
            <div class="slider-demo-block flex">
              <el-slider
                  :min="1"
                  :max="30"
                  :step="1"
                  show-stops v-model="formData.creativity"/>
              <div class="slider-input">
                <el-input-number
                    v-model="formData.creativity"
                    :min="1"
                    :max="30"
                    class="mx-4"
                    controls-position="right"
                />
              </div>
            </div>
          </div>
          <div class="silder-item">
            <div class="tips">今日剩余额度 <b>{{ state.user.quota }}</b></div>
          </div>
        </div>
      </div>
      <div class="s-footer center-center">
        <el-button size="large" class="btn-wid" type="primary" :loading="loading" @click="creatTask">开始生成
        </el-button>
      </div>
    </div>
  </el-scrollbar>
</template>
<script setup>
import {ref, reactive, onMounted, getCurrentInstance, watch} from 'vue'
import {useStore} from 'vuex'
import {ElMessage} from 'element-plus'
import emitter from '@/utils/bus'
import {
  typeList,
  useScenelist,
  imageTypeList,
  // materialList,
  styleList,
  mainMaterialList,
  technologyList,
  styleColorList,
  mosaicMaterial,
  imageScaleList,
  imageSizeList,
  typeGroup,
  refrigerator, // 冰箱
  electricRiceCooker, // 电饭煲
  vacuumCleaner, // 吸尘器
  rechargeableToothbrush, //电动牙刷
  laptopComputer, // 笔记本电脑
  carTypeList, //汽车
} from '@/utils/formEnum'
import {postCreatTask} from '@/network/api/user'

const internalInstance = getCurrentInstance()
const props = defineProps({
  taskDetail: Object
})

let {state, dispatch} = useStore()

let loading = ref(false)
const rainGroup = ref([
  {label: '2048:2048', value: 1},
  {label: '4096:4096', value: 2},
  {label: '1636:1536', value: 3},
])
let categoryList = ref(carTypeList)
let categoryListOther = ref(typeList)
let formData = ref({
  product: 'car',
  description: '',
  category: [],
  scene: '',
  workmanship: '',
  pattern: '',
  modeling: '',
  mainMaterial: '',
  studdedMaterial: '',
  style: '',
  sizeStyle: 1,
  size: 1,
  creativity: 7
})

function getTypeName() {
  let type = formData.value.product;
  formData.value.category = '';
  setData(type)
}

function setData(type) {
  categoryList.value = []
  if (type === 'jewelry') { // 珠宝
    // categoryList.value = typeList
  } else if(type === 'car') {
    categoryList.value = carTypeList
  } else if (type === 'electricRiceCooker') { // 电饭煲
    categoryList.value = electricRiceCooker
  } else if (type === 'refrigerator') { // 冰箱
    categoryList.value = refrigerator
  } else if (type === 'electricToothbrush') { // 充电牙刷
    categoryList.value = rechargeableToothbrush
  } else if (type === 'dustCollector') { // 吸尘器
    categoryList.value = vacuumCleaner
  } else if (type === 'notebookComputer') { // 笔记本电脑
    categoryList.value = laptopComputer
  } else {
    categoryList.value = []
  }
  // console.log(categoryList.value)
}

function onGetScalc(item) {
  formData.value.sizeStyle = item.value
  internalInstance.ctx.$forceUpdate()
}

function onGetSize(item) {
  formData.size = item.value
  internalInstance.ctx.$forceUpdate()
}

function onSetRatin(item) {
  formData.value.size = item.value
  internalInstance.ctx.$forceUpdate()
}

// 创建任务
const creatTask = async () => {
  // console.log(formData.value)
  const {
    description,
    category,
    scene,
    workmanship,
    pattern,
    modeling,
    mainMaterial,
    studdedMaterial,
    style,
    sizeStyle,
    size,
    creativity,
    product
  } = formData.value
  let param = {
    product,
    description,
    category,
    scene,
    workmanship,
    pattern,
    modeling,
    mainMaterial,
    studdedMaterial,
    style,
    sizeStyle,
    size,
    creativity
  }
  if (product !== 'jewelry') {
    if (category) {
      param.category = [category]
    } else {
      param.category = []
    }
  } else {
    // console.log(param.category)
    if (param.category === null || param.category === "") {
      param.category = [];
    }
  }


  loading.value = true
  try {
    const res = await postCreatTask(param)
    if (res && res.code === 0) {
      loading.value = false
      emitter.emit('getDaskDetail', res.data)
      ElMessage.success(res.message)
      dispatch('user/getInfo')
    }
  } catch (err) {
    loading.value = false
  }
}
// const isPage = ref(false)
watch(() => props.taskDetail, (newVal) => {
      if (newVal) {
        setData(newVal.task.product)
        Object.assign(formData.value, newVal.task)
        if (newVal.task.product != 'jewelry') {
          if (newVal.task.category) {

          }
          formData.value.category = newVal.task.category.join('')
        }
      }
    },
    {deep: true}
)
onMounted(() => {
  if (JSON.stringify(props.taskDetail.task) != '{}') {
    // console.log(JSON.stringify(props.taskDetail.task) == {})
    formData.value = props.taskDetail.task
  }
})
</script>
<style lang="scss" scoped>
.form-main {
  padding-left: 22px;
  padding-right: 32px;
}

.sub-title {
  padding-top: 12px;
  padding-bottom: 14px;
}

.slider-input {
  padding-left: 10px;
}

.wid100 {
  width: 100%;
}

.scale-item {
  width: 100%;
  height: 54px;
  box-sizing: border-box;
  background-color: var(--el-fill-color-blank);
  border-radius: 10px;
  border-width: 2px;
  text-align: center;

  .scale-text {
    font-size: 12px;
    line-height: 20px;
    color: var(--page-textsub-color);
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    border-style: solid;
    border-color: #4A0DA3;
  }

  .scale {
    font-size: 14px;
    color: var(--page-textsub-color);
  }

  .scale-name {
    margin-top: 5px;
  }
}

.silder-item {
  .tips {
    padding-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #fc011a;
  }
}

.s-footer {
  margin-top: 40px;
  padding-top: 23px;
  padding-bottom: 28px;
  border-top: 1px solid var(--page-border-color);

  .btn-wid {
    width: 328px;
  }
}
</style>
