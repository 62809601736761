// 类型
export const typeList = [
  {value: 'Pendant/Decoration', label: '挂件／挂饰'},
  {value: 'Bracelet', label: '手链'},
  {value: 'Nanyang Gold Beads', label: '南洋金珠'},
  {value: 'Necklace', label: '项链'},
  {value: 'Silver Jewelry', label: '银制品'},
  {value: 'Mabe Pearl', label: '马贝珠'},
  {value: 'Earrings', label: '耳饰'},
  {value: 'Freshwater Non-Nucleated Pearl', label: '淡水无核珍珠'},
  {value: 'Hair Accessories', label: '发圈'},
  {value: 'Jewelry Box', label: '首饰盒'},
  {value: 'Mobile Phone Strap', label: '手机链'},
  {value: 'Keshi Pearl', label: '客旭珍珠'},
  {value: 'Ear Stud', label: '耳钉'},
  {value: 'Tie Clip', label: '领带夹'},
  {value: 'Earrings', label: '耳环'},
  {value: 'Bangle', label: '手镯'},
  {value: 'Beaded Bracelet', label: '手串'},
  {value: 'Bangle', label: '把件'},
  {value: 'Hairpin', label: '发簪'},
  {value: 'Necklace and Bracelet Set', label: '套链'},
  {value: 'Beaded Jewelry', label: '配珠'},
  {value: 'Other', label: '其他'},
  {value: 'Gemstone', label: '裸石'},
  {value: 'Ear Clip', label: '耳夹'},
  {value: 'Tahitian Black Pearl', label: '大溪地黑珍珠'},
  {value: 'Freshwater Nucleated Pearl', label: '淡水有核珍珠'},
  {value: 'Gold Jewelry', label: '金制品'},
  {value: 'Ring', label: '戒指'},
  {value: 'Nanyang Australian White', label: '南洋澳白'},
  {value: 'Pendant', label: '吊坠'},
  {value: 'Leather Chain', label: '皮包链'},
  {value: 'Ankle Bracelet', label: '脚链'},
  {value: 'Earrings', label: '耳坠'},
  {value: 'Ornament', label: '挂件'},
  {value: 'Ear Threader', label: '耳线'},
  {value: 'Bracelet/Bangle', label: '手链/手镯'},
  {value: 'Akoya Pearl', label: 'AKOYA'},
  {value: 'Ornament/Bangle/Pendant', label: '摆件/把件/挂件'}
]
// 适用场景
export const useScenelist = [
  {value: 'Investment/Storage of Value', label: '投资储值'},
  {value: 'Car Decoration', label: '汽车装饰'},
  {value: 'Year of the Zodiac Animal', label: '本命年'},
  {value: 'Gift for Friend', label: '送朋友'},
  {value: 'Gift for Mother', label: '送母亲'},
  {value: 'Gift for Boyfriend', label: '送男友'},
  {value: 'Proposal', label: '求婚'},
  {value: 'Play/Collection', label: '盘玩'},
  {value: 'Personal Use', label: '自戴'},
  {value: 'Other', label: '其他'},
  {value: 'Gift for Girlfriend', label: '送女友'},
  {value: 'Gift for Wife', label: '送老婆'},
  {value: 'Wedding Celebration', label: '婚庆'},
  {value: 'Gift', label: '礼品'},
  {value: 'Birthday Gift', label: '生日礼物'},
  {value: 'Personal Use', label: '自用'},
  {value: 'Collection', label: '收藏'},
  {value: 'Gift/Give as Present', label: '馈赠'},
  {value: 'Gift for Husband/Boyfriend', label: '送老公/男友'},
  {value: 'Gift for Elderly', label: '送长辈'},
  {value: 'Gift for Father', label: '送父亲'}
]
// 图案造型
export const imageTypeList = [
  {value: 'Circular/Round', label: '圆形'},
  {value: 'Plants/Flowers', label: '植物花卉'},
  {value: 'Guanyin (Buddhist goddess of compassion)', label: '观音'},
  {value: 'Cartoon', label: '卡通'},
  {value: 'Flower and Leaves', label: '花叶'},
  {value: 'Animals', label: '动物'},
  {value: 'Mascot', label: '吉祥物'},
  {value: 'Round Beads', label: '圆珠'},
  {value: 'Teardrop Shape', label: '水滴形'},
  {value: 'Text/Characters', label: '文字'},
  {value: 'Lucky Beads', label: '转运珠'},
  {value: 'Numbers', label: '数字'},
  {value: 'Chinese Character "Fu" (Blessing)', label: '福字'},
  {value: 'Dragon and Phoenix', label: '龙凤'},
  {value: 'Buddhist Statues', label: '佛像'},
  {value: 'Zodiac Constellations', label: '星座'},
  {value: 'Wedding Celebration', label: '婚庆'},
  {value: 'Ruyi (Scepter/Ornament symbolizing good fortune)', label: '如意'},
  {value: 'Pixiu (Chinese mythical creature)', label: '貔貅'},
  {value: 'Jade Pendant', label: '玉牌'},
  {value: 'Chinese Zodiac', label: '生肖'},
  {value: '"Lu Lu Tong" (Homonym for Good Luck in Chinese)', label: '路路通'},
  {value: 'Smooth Surface', label: '光面'},
  {value: '"Ping An Kou" (Button with a Blessing for Safety and Peace)', label: '平安扣'},
  {value: 'Heart Shape', label: '心形'},
  {value: 'Stars and Moon', label: '星辰月亮'},
  {value: 'Guan Gong (Chinese deity of loyalty and righteousness)', label: '关公'},
  {value: 'Characters/People', label: '人物'},
  {value: "Baby's Breath (Gypsophila flowers)", label: '满天星'},
  {value: 'Alphabet/Letters', label: '字母'}
]
// 材质
export const materialList = [
  {value: 'Alloy', label: '合金'},
  {value: 'Bodhi Seed (also called Star and Moon Bodhi)', label: '星月菩提'},
  {value: 'Red Jasper', label: '红纹石'},
  {value: 'Copper', label: '铜'},
  {value: 'Agate', label: '玛瑙'},
  {value: 'Clear Quartz/Crystal', label: '白水晶'},
  {value: '925 Sterling Silver', label: '足银'},
  {value: 'Glass Crystal', label: '仿水晶'},
  {value: 'Synthetic Crystal', label: '合成水晶'},
  {value: 'Citrine', label: '黄水晶'},
  {value: 'PT999 (999 Platinum)', label: 'pt999'},
  {value: "Cat's Eye Bodhi", label: '凤眼菩提'},
  {value: 'Rose Quartz', label: '粉晶'},
  {value: 'Mookaite Jasper', label: '莫桑石'},
  {value: 'Titanium Steel', label: '钛钢'},
  {value: 'Amethyst', label: '紫水晶'},
  {value: '925 Silver', label: '925银'},
  {value: 'Obsidian', label: '黑曜石'},
  {value: 'Cubic Zirconia', label: '锆石'},
  {value: 'Tiger Eye Stone', label: '虎眼石'},
  {value: 'Rudraksha', label: '金刚菩提'},
  {value: 'Synthetic Cubic Zirconia/Diamond', label: '合成立方氧化锆/水钻'},
  {value: 'Thai Silver', label: '泰银'},
  {value: 'Natural Crystal', label: '天然水晶'},
  {value: 'PT990 (990 Platinum)', label: 'pt990'},
  {value: 'Swarovski Crystal', label: '施华洛世奇水晶'},
  {value: 'Tungsten Gold', label: '钨金'},
  {value: 'PT950 (950 Platinum)', label: 'pt950'},
  {value: 'Lapis Lazuli', label: '青金石'},
  {value: 'Bodhi Root', label: '菩提根'},
  {value: 'Other', label: '其他'},
  {value: 'Alloy/Plated Gold/Plated Silver', label: '合金/镀金/镀银'},
  {value: 'Wood', label: '木质'},
  {value: 'Nephrite Jade', label: '玉髓'},
  {value: 'Cinnabar', label: '朱砂'},
  {value: 'Pearl', label: '珍珠'},
  {value: 'Nanhong Agate', label: '南红'},
  {value: 'K Gold', label: 'K金'},
]
// 款式
export const styleList = [
  {value: 'Starry sky', label: '满天星'},
  {value: 'Pendant', label: '挂件'},
  {value: 'Water wave chain', label: '水波链'},
  {value: 'Twisted chain', label: '扭片链'},
  {value: 'Chopin chain', label: '肖邦链'},
  {value: 'Whip chain', label: '马鞭链'},
  {value: 'Set chain', label: '套链'},
  {value: 'Ornament', label: '摆件'},
  {value: 'Pendant', label: '吊坠'},
  {value: 'Cross chain', label: '十字链'},
  {value: 'Box chain', label: '盒子链'},
  {value: 'Starry sky chain', label: '满天星链'},
  {value: 'Water ripple', label: '水波纹'},
  {value: 'Melon seed chain', label: '瓜子链'},
  {value: 'Thai chain', label: '泰国链'},
  {value: 'Bracelet', label: '手串'},
  {value: 'Ingot', label: '元宝'},
  {value: 'Other', label: '其他'},
  {value: 'Bracelet', label: '手链'},
  {value: 'Beaded chain', label: '圆珠链'},
  {value: 'Snake bone chain', label: '蛇骨链'},
  {value: 'Bangle', label: '手镯'},
  {value: 'O-shaped chain', label: 'O字链'},
  {value: 'Bamboo section chain', label: '竹节链'},
  {value: 'Necklace', label: '项链'}
]
// 主体材质
export const mainMaterialList = [
  {value: 'Brass', label: '黄铜'},
  {value: 'Turquoise', label: '绿松石'},
  {value: 'Acrylic', label: '仿水晶'},
  {value: 'Garnet', label: '石榴石'},
  {value: 'Moissanite + South Sea Mother-of-Pearl', label: '莫桑钻+南洋母贝'},
  {value: "Tiger's Eye", label: '虎眼石'},
  {value: 'Cinnabar', label: '朱砂'},
  {value: 'Tibetan Silver', label: '藏银'},
  {value: 'Tourmaline', label: '碧玺'},
  {value: 'Phantom Quartz', label: '幽灵/异像'},
  {value: 'Nephrite Jade', label: '玉髓'},
  {value: 'Shell pearl', label: '贝珠'},
  {value: 'Strawberry Crystal', label: '草莓晶'},
  {value: '925 Sterling Silver', label: '925银'},
  {value: 'Clear Quartz', label: '白水晶'},
  {value: 'Agate', label: '玛瑙'},
  {value: 'Citrine', label: '黄水晶'},
  {value: 'Pearl', label: '珍珠'},
  {value: 'Aquamarine', label: '海蓝宝'},
  {value: 'Amethyst', label: '紫水晶'},
  {value: '990 Silver', label: '足银'},
  {value: 'Alloy/ Gold-plated/Silver-plated', label: '合金／镀金/镀银'},
  {value: 'Blue Topaz', label: '海蓝宝/托帕石'},
  {value: 'Others', label: '其他'},
  {value: 'Obsidian', label: '黑曜石'},
  {value: 'Titanium Steel', label: '钛钢'},
  {value: 'Nanhong Agate', label: '南红'},
  {value: 'Rose Quartz', label: '粉晶'},
  {value: 'Titanium Crystal', label: '钛晶/发晶'},
  {value: 'Lapis Lazuli', label: '青金石'},
  {value: 'Rhodonite', label: '红纹石'},
  {value: 'Moonstone/Sunstone', label: '月光石/日光石'}
]
// 工艺
export const technologyList = [
  {value: 'Hard gold', label: '硬金'},
  // {value: '硬金', label: '硬金'},
  {value: 'Handmade', label: '手工'},
  {value: 'Traditional craftsmanship', label: '古法'},
  {value: 'Enamel', label: '珐琅'},
  {value: '5G', label: '5G'},
  {value: 'Mechanized shaping', label: '机制成型'},
  {value: 'Filigree', label: '花丝'},
  {value: 'Engraving', label: '车花'},
  {value: 'Inlaying', label: '镶嵌'}
]
// 风格
export const styleColorList = [
  {value: 'Bohemian style', label: '波西米亚'},
  {value: 'Literary style', label: '文艺风'},
  {value: 'Ethnic style', label: '民族风'},
  {value: 'Retro style', label: '复古风'},
  {value: 'Casual style', label: '休闲风'},
  {value: 'Palace style', label: '宫廷风'},
  {value: 'Minimalist style', label: '简约风'},
  {value: 'Sweet style', label: '甜美风'},
  {value: 'Punk style', label: '朋克风'},
  {value: 'Luxury style', label: '奢华风'},
  {value: 'Elegant style', label: '优雅风'},
  {value: 'Fresh and simple style', label: '小清新'},
  {value: 'Modern style', label: '现代风'},
  {value: 'Japanese and Korean style', label: '日韩风'},
  {value: 'Trendy style', label: '潮流风'},
  {value: 'European and American style', label: '欧美风'},
  {value: 'Chinese style', label: '中国风'}
]
// 镶嵌材质
export const mosaicMaterial = [
  {value: 'Cubic zirconia', label: '锆石'},
  {value: 'Alloy inlay', label: '合金镶嵌'},
  {value: 'Gold inlay', label: '金镶嵌'},
  {value: 'Shell pearl', label: '贝珠'},
  {value: 'Pearl', label: '珍珠'},
  {value: 'Platinum 950', label: 'pt950'},
  {value: 'Silver inlay', label: '银镶嵌'},
  {value: 'No inlay', label: '无镶嵌'},
  {value: 'K gold inlay gemstones', label: 'k金镶宝石'},
  {value: '18K yellow gold', label: '黄18K金'},
  {value: '18K rose gold', label: '18k玫瑰金'},
  {value: 'Artificial gemstone', label: '人工宝石'},
  {value: 'Silver inlay gemstones', label: '银镶宝石'},
  {value: 'Other', label: '其他'},
  {value: 'K gold inlay', label: 'K金镶嵌'},
  {value: 'Natural gemstone', label: '天然宝石'},
  {value: '18K white gold', label: '白18k金'}
]

export const imageScaleList = [
  {value: 1, label: '头像图', size: '1:1'},
  // {value: 2, label: '手机屏幕', size: '1:2'},
  // {value: 3, label: '文章配图', size: '4:3'},
  // {value: 4, label: '宣传海报', size: '9:16'}
]

export const imageSizeList = [
  {value: 1, label: '512*512'},
]
export const typeGroup = [
  { label: '汽车', value: 'car' },
  { label: '珠宝', value: 'jewelry' },
  { label: '电饭煲', value: 'electricCooker' },
  { label: '冰箱', value: 'refrigerator' },
  { label: '电动牙刷', value: 'electricToothbrush' },
  { label: '吸尘器', value: 'dustCollector' },
  { label: '笔记本电脑', value: 'notebookComputer' }
]
// 冰箱
export const refrigerator = [
  {value: 'Double-doors', label: '对开门'},
  {value: 'Triple-door', label: '三门'},
  {value: 'Double-door', label: '双门'},
  {value: 'Single-door', label: '单门'}
]
// 电饭煲
export const electricRiceCooker = [
  // {value: 'Bracelet', label: '手链'},
]
// 吸尘器
export const vacuumCleaner = [
  {value: 'Handheld', label: '手持'},
  {value: 'Vehicle-mounted', label: '车载'},
  {value: 'Bucket-type', label: '桶式'}
]
// 电动牙刷
export const rechargeableToothbrush = [
  {value: 'Adult', label: '成人'},
  {value: 'Child', label: '儿童'}
]
// 笔记本电脑
export const laptopComputer = [
  {value: 'Notebook', label: '笔记本'},
  {value: 'Gaming laptop', label: '游戏本'},
  {value: 'Tablet', label: '平板'},
]
// 汽车
export const carTypeList = [
  {value: 'Sedan', label: '轿车'},
  {value: 'Sports Car', label: '跑车'},
  {value: 'City SUV', label: '城市越野'},
  {value: 'Hardcore SUV', label: '硬派越野'},
  {value: 'Minivan', label: '商务车'},
  {value: 'Bus', label: '客车'},
  {value: 'Van', label: '面包车'},
  {value: 'Pickup Truck', label: '皮卡车'},
  {value: 'Convertible', label: '敞篷车'},
  {value: 'Race Car', label: '赛车'}
]

